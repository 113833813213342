<template>
    <div class="profile-edit">
      <form @submit.prevent="submitForm" style="margin: 2em">
        <!-- Name Field -->
        <div class="form-group" style="display: flex; align-items: center;">
          <label style="width: 12em;" for="name">Nome</label>
          <input type="text" id="name" v-model="profile.name" />
        </div>

        <!-- Surname Field -->
        <div class="form-group" style="display: flex; align-items: center;">
          <label style="width: 12em;" for="surname">Sobrenome</label>
          <input type="text" id="surname" v-model="profile.surname" />
        </div>

        <!-- Email Field -->
        <div class="form-group" style="display: flex; align-items: center;">
          <label style="width: 12em;" for="email">Email</label>
          <input type="email" id="email" v-model="profile.email" />
        </div>

        <!-- Cellphone Field -->
        <div class="form-group" style="display: flex; align-items: center;">
          <label style="width: 12em;" for="cellphone">Telefone</label>
          <input type="text" id="cellphone" v-model="profile.cellphone" />
        </div>

        <!-- Description Field -->
        <div class="form-group">
          <label for="description">Descrição sobre você</label>
          <quill-editor
              id="description"
              v-model='profile.description'
              ref='textEditor'
              :options='editorOption'
            ></quill-editor>
        </div>

        <!-- Profile Picture Upload Field
        <div class="form-group">
          <label for="profilePicture">Foto de Perfil</label>
          <input type="file" id="profilePicture" @change="handleFileUpload('profilePicture')" ref="profilePicture" />
        </div> -->

        <!-- Submit Button -->
        <div class="button" style="text-align: right;">
            <button type="submit">Salvar</button>
        </div>
    </form>
    </div>
  </template>

<script>
  import main from '../assets/scripts/main.js';
  import 'quill/dist/quill.bubble.css';
  import 'quill/dist/quill.core.css';
  import 'quill/dist/quill.snow.css';

  export default {
    name: 'Profile',
    data() {
        return {
            profile: {
            name: '',
            surname: '',
            email: '',
            cellphone: '',
            description: '',
            profilePicture: null,
          },
          editorOption: { /* quill options */ },
        };
    },
    created() {
        let username = JSON.parse(localStorage.getItem('userLogado')).usuario;
        this.fetchUserProfile(username);
    },
    methods: {
        async fetchUserProfile(username) {
            var req = { user: username }
            var response = await main.postApi(req, "admin/pegarUsuario");
            var retorno = await response.json();

            if (retorno.sucesso) {
              // Update profile data with the response
              this.profile = {
                ...this.profile,
                name: retorno.corpo.Nome || '',
                surname: retorno.corpo.Sobrenome || '',
                email: retorno.corpo.Email || '',
                cellphone: retorno.corpo.Telefone || '',
                description: retorno.corpo.Descricao || '',
                profilePicture: retorno.corpo.profilePicture || null,
              };
            }
        },
        handleFileUpload(field) {
          const file = this.$refs[field].files[0];
          if (field === 'cv') {
            this.profile.cv = file;
          } else if (field === 'profilePicture') {
            const reader = new FileReader();
            reader.readAsArrayBuffer(file);
            reader.onload = (e) => {
              this.profile.profilePicture = new Uint8Array(e.target.result);
            };
          }
        },
        async submitForm() {
            console.log('Profile data:', this.profile);
            let username = JSON.parse(localStorage.getItem('userLogado')).usuario;
            let token = localStorage.getItem('cedeppeToken');

            var req = {
              Usuario: username,
              Token: token,
              Nome: this.profile.name,
              Sobrenome: this.profile.surname,
              Email: this.profile.email,
              Telefone: this.profile.cellphone,
              Descricao: this.profile.description,
              FotoPerfil: this.profile.profilePicture,
            }

            var response = await main.postApi(req, "admin/EditarInfoUsuario");
            var retorno = await response.json();

            if (retorno.sucesso) {
              console.log('Profile saved successfully');
            } else {
              console.error('Error saving profile:', error);
            }
        },
      },
    };
</script>

<style scoped>
    .profile-edit {
        max-width: 600px;
        margin: 0 auto;
    }

    .form-group {
        margin-bottom: 15px;
    }

    .form-group label {
        display: block;
        margin-bottom: 5px;
    }

    .form-group input,
    .form-group textarea {
        width: 100%;
        max-width: 1000px;
        padding: 8px;
        box-sizing: border-box;
        border: 1px solid #ccc;
        border-radius: 4px;
    }

    button {
        padding: 10px 20px;
        background-color: red;
        color: white;
        border: none;
        border-radius: 4px;
        cursor: pointer;
    }
</style>